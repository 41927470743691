import React, { useState } from 'react';
import styled from 'styled-components';
import { ImageItem } from '../Type/Types';
import * as THREE from 'three';
import { useDispatch, useSelector } from 'react-redux';
import { setSelectedHDRI, setSelectedImage, setImageTextures, removeImage, setImageScale } from '../store/concentricCirclesSlice';
import TextInput from './TextInput'; // 텍스트 입력 컴포넌트 import
import ImageGrid from './ImageGrid'; // 그리드 컴포넌트 import
import { RootState } from '../store';

interface ControlPanelProps {
  cameraPosition: THREE.Vector3; // 카메라 위치
  cameraDirection: THREE.Vector3; // 카메라의 정면 방향
  cameraUp: THREE.Vector3; // 카메라의 위쪽 방향 (필요할 경우 추가)
}


const ControlPanel: React.FC<ControlPanelProps> = ({ cameraPosition, cameraDirection,cameraUp }) => {
  const dispatch = useDispatch();
  const { imageTextures: images, selectedImage, selectedHDRI } = useSelector(
    (state: RootState) => state.concentricCircles
  );

  const [scale, setScale] = useState<number>(selectedImage?.Scale || 10);
  
  // 하드코딩된 HDRI 파일 경로
  const hdris = [
    './Hdr/red.exr',
    './Hdr/white.exr',
    './Hdr/black.exr',
    './Hdr/purple.exr',
    './Hdr/white2.exr',
  ];

  const onImageSelect = (image: ImageItem) => {
    dispatch(setSelectedImage(image));
    setScale(image.Scale); // 선택된 이미지의 스케일 설정
  };
  
  const on1ImageUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const url = URL.createObjectURL(file);
  
      // 이미지 크기 기반으로 스케일 계산
  
      const newImageItem: ImageItem = {
        url,
        position: new THREE.Vector3(-10,10,-30),
        Scale: 10, // 이미지 크기에 맞춘 스케일
      };
  
      dispatch(setImageTextures([...images, newImageItem]));
    } else {
      console.log("File not available");
    }
  };
  
  const on2ImageUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const url = URL.createObjectURL(file);
      // 이미지 크기 기반으로 스케일 계산
  
      const newImageItem: ImageItem = {
        url,
        position: new THREE.Vector3(5,-5,-10),
        Scale: 5, // 이미지 크기에 맞춘 스케일
      };
  
      dispatch(setImageTextures([...images, newImageItem]));
    } else {
      console.log("File not available");
    }
  };

  const onImageDelete = () => {
    if (selectedImage) {
      console.log("Deleting image:", selectedImage);
      dispatch(removeImage(selectedImage));
    } else {
      console.log("No image selected for deletion");
    }
  };

  const handleScaleChange = () => (event: React.ChangeEvent<HTMLInputElement>) => {
    const newScale = parseFloat(event.target.value);

    setScale(newScale);  // Update the local scale state
    // Now update the selected image's scale in Redux
    if (selectedImage) {
      dispatch(setImageScale({ image: selectedImage, scale: newScale }));
    }
  };

  const handleHDRISelect = (hdri: string) => {
    dispatch(setSelectedHDRI(hdri)); // Set the selected HDRI
  };

  return (
    <ControlWrapper>
      <label htmlFor="image-upload">Upload Image:</label>
      <input type="file" id="image-upload" accept="image/*" onChange={on1ImageUpload} />
      <input type="file" id="image-upload" accept="image/*" onChange={on2ImageUpload} />

      <TextInput />

      {/* HDRI Selection */}
      <label>Select HDRI:</label>
      <select onChange={(e) => handleHDRISelect(e.target.value)} value={selectedHDRI}>
        {hdris.map((hdri, index) => (
          <option key={index} value={hdri}>
            {hdri}
          </option>
        ))}
      </select>

      {/* 스케일 슬라이더 */}
      <label>Width Scale: </label>
      <input
        type="range"
        min="1"
        max="50"
        value={scale} // Bind the width scale to the first element
        onChange={handleScaleChange()} // Update width scale
      />
      <span>{scale}</span>


      <button onClick={onImageDelete} disabled={!selectedImage}>
        Delete Selected Image
      </button>

      <ImageGrid images={images} selectedImage={selectedImage} onImageSelect={onImageSelect} />
    </ControlWrapper>
  );
};

export default ControlPanel;

const ControlWrapper = styled.div`
  position: absolute;
  top: 10px;
  left: 10px;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 10px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  z-index: 10;
`;