
// Vertex Shader
export const vertexShader = `
varying vec2 vUv;
varying vec3 vWorldPosition;

void main() {
  vUv = uv;
  vWorldPosition = (modelMatrix * vec4(position, 1.0)).xyz;
  gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
}
`;

// Fragment Shader: Only apply reflection (no color mixing)
export const fragmentShader = `
uniform sampler2D uTexture;  // Webcam video texture
uniform vec3 uKeyColor;      // Chroma key color
uniform float uThreshold;    // Chroma key threshold
uniform float uSoftness;     // Softness for keying
varying vec2 vUv;
varying vec3 vWorldPosition;

void main() {
  // Sample the webcam video texture
  vec4 color = texture2D(uTexture, vUv);
  
  // Chroma key transparency calculation
  float dist = distance(color.rgb, uKeyColor); 
  float alpha = smoothstep(uThreshold, uThreshold + uSoftness, dist); // Transparency calculation

  // Calculate the normal for reflection using vWorldPosition
  vec3 normal = normalize(cross(dFdx(vWorldPosition), dFdy(vWorldPosition)));
  
  // Get the view direction (camera position)
  vec3 viewDir = normalize(vWorldPosition- cameraPosition);

  // Use the normal and view direction to create a reflection vector
  vec3 reflection = reflect(viewDir, normal); 

  // Apply brightness as a factor to the original color
  vec3 finalColor = color.rgb; // Modulate object color by environment brightness

  // Output the final color with transparency based on chroma keying
  gl_FragColor = vec4(finalColor, alpha);
}
`;