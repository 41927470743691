import React, { useRef, useEffect } from 'react';
import { Billboard, Image } from '@react-three/drei';
import * as THREE from 'three';
import { ImageItem } from '../Type/Types';
import { useThree } from '@react-three/fiber';

type ImportedImageProps = {
  TextureImage: ImageItem | null;
  name: string;
  [key: string]: any;
};

function ImportedImage({ name, TextureImage, ...props }: ImportedImageProps): JSX.Element | null {
  const imageRef = useRef<THREE.Mesh>(null);
  const { camera } = useThree(); // Get the camera from Three.js

  useEffect(() => {
    if (!camera) {
      console.warn('Camera is not available.');
      return;
    }

    if (!imageRef.current) {
      console.warn(`Image reference for "${name}" is not available.`);
      return;
    }

    if (!TextureImage) {
      console.warn(`TextureImage data is missing for "${name}".`);
      return;
    }

    try {
      // Calculate the direction from the image to the camera
      const direction = new THREE.Vector3().subVectors(camera.position, imageRef.current.position);

      // Calculate the angle to rotate the image so that it faces the camera on the Y-axis
      const angle = Math.atan2(direction.x, direction.z);

      // Apply the calculated angle to the image rotation on the Y-axis
      imageRef.current.rotation.y = angle;
    } catch (error) {
      console.error(`Error updating rotation for image "${name}":`, error);
    }
  }, [TextureImage, camera, name]);

  // Return null if no TextureImage or if essential data is missing
  if (!TextureImage || !TextureImage.position || !TextureImage.url) {
    console.warn(`Invalid TextureImage data for "${name}".`);
    return null;
  }

  return (
    <Billboard>
      <Image
        name={name}
        ref={imageRef}
        url={TextureImage.url}
        scale={TextureImage.Scale}
        position={TextureImage.position}
        {...props}
        transparent
      />
    </Billboard>
  );
}

export default ImportedImage;
