import { useEffect, useRef } from 'react';

export function useWebcam(): React.RefObject<HTMLVideoElement> {
  const videoRef = useRef<HTMLVideoElement | null>(null);

  useEffect(() => {
    const videoElement = videoRef.current;

    if (videoElement) {
      navigator.mediaDevices
        .getUserMedia({ video: true })
        .then((stream) => {
          videoElement.srcObject = stream;

          // Play the video when metadata is loaded
          videoElement.onloadedmetadata = () => {
            videoElement.play().catch((err) => {
              console.error("Error playing video: ", err);
            });
          };
        })
        .catch((err) => {
          console.error("Error accessing webcam: ", err);
        });
    }

    // Cleanup on unmount
    return () => {
      if (videoElement && videoElement.srcObject) {
        const stream = videoElement.srcObject as MediaStream;
        const tracks = stream.getTracks();
        tracks.forEach((track) => track.stop());
      }
    };
  }, []);

  return videoRef;
}
