import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { set3DText } from '../store/concentricCirclesSlice';
import styled from 'styled-components';
import * as THREE from 'three'


const TextInput: React.FC = () => {
  const dispatch = useDispatch();
  const [textInput, setTextInput] = useState('');

  const handleTextSubmit = () => {
    if (textInput.trim() !== '') {
      dispatch(set3DText(textInput)); // Redux 상태에 입력된 텍스트 저장
      setTextInput(''); // 입력 필드 초기화
    }
  };

  return (
    <TextInputWrapper>
      <input
        type="text"
        placeholder="Enter 3D text"
        value={textInput}
        onChange={(e) => setTextInput(e.target.value)}
      />
      <button onClick={handleTextSubmit}>Add Text</button>
    </TextInputWrapper>
  );
};

export default TextInput;

const TextInputWrapper = styled.div`
  margin-top: 10px;
  display: flex;
  gap: 8px;

  input {
    padding: 5px;
    flex: 1;
  }

  button {
    padding: 5px 10px;
    background-color: #007bff;
    color: white;
    border: none;
    cursor: pointer;
    border-radius: 4px;

    &:hover {
      background-color: #0056b3;
    }
  }
`;
