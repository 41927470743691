import React, { useEffect, useRef, useState } from 'react';
import * as THREE from 'three';
import { useFrame, Vector3 } from '@react-three/fiber';
import { Billboard } from '@react-three/drei';
import { EXRLoader } from 'three-stdlib';
import { fragmentShader, vertexShader } from '../Type/Shaders';

interface VideoPlaneProps {
  position: Vector3;
  selectedHDRI: string;
  [key: string]: any;
}

function VideoPlane({ position, selectedHDRI, ...props }: VideoPlaneProps): JSX.Element | null {
  const [videoTexture, setVideoTexture] = useState<THREE.VideoTexture | null>(null);
  const [videoLoaded, setVideoLoaded] = useState<boolean>(false);
  const meshRef = useRef<THREE.Mesh>(null);
  const videoRef = useRef<HTMLVideoElement | null>(null); // Video reference
  const [envMap, setEnvMap] = useState<THREE.Texture | null>(null);

  useEffect(() => {
    // Dynamically create a video element
    const videoElement = document.createElement('video');
    videoElement.src = './models/Download1.mp4'; // Path to your local video file
    videoElement.crossOrigin = 'anonymous'; // For CORS handling if necessary
    videoElement.loop = true; // Optional: loop the video
    videoElement.muted = true; // Required for autoplay on some browsers
    videoElement.playsInline = true; // For iOS autoplay compatibility
    videoRef.current = videoElement;

    // Set up the video texture
    const setupVideoTexture = () => {
      try {
        const texture = new THREE.VideoTexture(videoElement);
        texture.minFilter = THREE.LinearFilter;
        texture.magFilter = THREE.LinearFilter;
        texture.generateMipmaps = false;
        setVideoTexture(texture);

        videoElement
          .play()
          .then(() => console.log('Video playback started.'))
          .catch((err) => console.error('Video playback failed:', err));

        videoElement.onloadeddata = () => setVideoLoaded(true);
      } catch (error) {
        console.error('Error setting up video texture:', error);
      }
    };

    setupVideoTexture();

    // Load HDR texture using EXRLoader
    try {
      new EXRLoader().load(
        selectedHDRI,
        (hdrTexture) => {
          hdrTexture.mapping = THREE.EquirectangularReflectionMapping;
          setEnvMap(hdrTexture);
        },
        undefined,
        (error) => console.error('Failed to load HDRI:', error)
      );
    } catch (error) {
      console.error('Error loading HDRI texture:', error);
    }

    return () => {
      // Cleanup video element and event listeners
      videoElement.pause();
      videoElement.src = '';
      videoElement.onloadeddata = null;
    };
  }, [selectedHDRI]);

  useFrame(() => {
    if (videoTexture && videoLoaded) {
      videoTexture.needsUpdate = true;
    }
  });

  if (!videoTexture || !videoLoaded || !envMap) {
    return null;
  }

  return (
    <Billboard follow>
      <mesh ref={meshRef} position={position} {...props}>
        <planeGeometry args={[9, 16]} />
        <shaderMaterial
          attach="material"
          uniforms={{
            uTexture: { value: videoTexture },
            uKeyColor: { value: new THREE.Color(0x00ff00) },
            uThreshold: { value: 0.7 },
            uSoftness: { value: 0.1 },
          }}
          vertexShader={vertexShader}
          fragmentShader={fragmentShader}
          transparent
        />
      </mesh>
    </Billboard>
  );
}

export default VideoPlane;
