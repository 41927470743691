import { configureStore } from '@reduxjs/toolkit';
import pageReducer from './pageSlice'; // 아래에서 생성할 슬라이스
import concentricCirclesReducer from './concentricCirclesSlice';

const store = configureStore({
  reducer: {
    page: pageReducer, // 슬라이스 리듀서를 연결
    concentricCircles: concentricCirclesReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
