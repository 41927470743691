import { createSlice, PayloadAction } from "@reduxjs/toolkit"

interface PageState {
    currentPage: string
}

const initialState: PageState = {
    currentPage: "/", // 초기값
}

const pageSlice = createSlice({
    name: "page",
    initialState,
    reducers: {
        setPage: (state, action: PayloadAction<string>) => {
            state.currentPage = action.payload
        },
    },
})

export const { setPage } = pageSlice.actions
export default pageSlice.reducer
