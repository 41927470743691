import React from 'react';
import { ImageItem } from '../Type/Types';
import styled from 'styled-components';

interface ImageGridProps {
  images: ImageItem[];
  selectedImage: ImageItem | null;
  onImageSelect: (image: ImageItem) => void;
}

const ImageGrid: React.FC<ImageGridProps> = ({ images, selectedImage, onImageSelect }) => {
  return (
    <GridWrapper>
      {images.map((image, index) => (
        <ImageWrapper
          key={index}
          onClick={() => onImageSelect(image)}
          isSelected={image === selectedImage}
        >
          <img src={image.url} alt={`uploaded-image-${index}`} />
        </ImageWrapper>
      ))}
    </GridWrapper>
  );
};

export default ImageGrid;

const GridWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
  margin-top: 10px;
  width: 100%;
  max-width: 300px;
`;

const ImageWrapper = styled.div<{ isSelected: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  height: 100px;
  border: 1px solid #fff;
  border-radius: 5px;
  transition: border-color 0.3s ease;

  ${({ isSelected }) =>
    isSelected &&
    `
    border-color: #FF90E9;
    box-shadow: 0 0 10px rgba(255, 144, 233, 0.6);
  `}

  img {
    max-width: 100%;
    max-height: 100%;
    object-fit: cover;
  }
`;
