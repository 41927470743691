import { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { setCurrentAudioVolume, setCurrentAudioPitch } from '../store/concentricCirclesSlice';

// Frequency to pitch mapping based on standard tuning (A4 = 440Hz)
const frequencyToPitch = (frequency: number): string => {
  const pitchClasses = ['do', 're', 'mi', 'fa', 'sol', 'la', 'si'];
  const referencePitch = 261.63; // Middle C (C4) in Hz
  const semitoneRatio = Math.pow(2, 1 / 12);
  const pitchIndex = Math.round(Math.log(frequency / referencePitch) / Math.log(semitoneRatio));

  // Map the pitch index to the closest musical note
  const noteIndex = (pitchIndex + 12) % 12; // Ensure index is within range (0-11)
  return pitchClasses[noteIndex];
};

// Custom hook for audio input
const useAudioInput = () => {
  const audioContextRef = useRef<AudioContext | null>(null);
  const analyserNodeRef = useRef<AnalyserNode | null>(null);
  const microphoneRef = useRef<MediaStreamAudioSourceNode | null>(null);
  const bandPassFilterRef = useRef<BiquadFilterNode | null>(null);
  const dispatch = useDispatch();
  const maxRMSRef = useRef<number>(0.01);  // Start with a small value to prevent 0 normalization

  // Function to calculate frequency from the frequency data
  const calculateFrequency = (dataArray: Float32Array): number => {
    let maxIndex = -1;
    let maxValue = -Infinity;
    for (let i = 0; i < dataArray.length; i++) {
      if (dataArray[i] > maxValue) {
        maxValue = dataArray[i];
        maxIndex = i;
      }
    }
    const nyquist = audioContextRef.current!.sampleRate / 2;
    const frequency = (maxIndex / dataArray.length) * nyquist;
    return frequency;
  };

  // Function to calculate RMS (Root Mean Square) for volume
  const calculateRMS = (dataArray: Float32Array): number => {
    let sum = 0;
  
    // Convert dB values to linear scale and calculate RMS
    for (let i = 0; i < dataArray.length; i++) {
      // Ignore very low values to prevent noise interference
      const linearValue = Math.pow(10, dataArray[i] / 20); // Convert dB to linear scale
      sum += linearValue * linearValue; // Square the linear values
    }
  
    const rms = Math.sqrt(sum / dataArray.length); // Root mean square
    return rms;
  };

  // Function to normalize RMS to a range of 0 to 1


// Function to normalize RMS to a range of 0 to 1
const normalizeRMS = (rms: number): number => {
  if (rms > maxRMSRef.current) {
    maxRMSRef.current = rms; // Update max RMS
  }

  // Set a reasonable minimum for maxRMSRef to prevent very small values
  const effectiveMax = Math.max(maxRMSRef.current, 0.1);

  // Normalize the RMS value
  const normalizedValue = rms / effectiveMax;

  // Scale to 0-100 range and round to integer
  return Math.round(normalizedValue * 100);
};
  useEffect(() => {
    const setupAudio = async () => {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
        audioContextRef.current = new AudioContext();
        analyserNodeRef.current = audioContextRef.current.createAnalyser();
        bandPassFilterRef.current = audioContextRef.current.createBiquadFilter();

        // Apply a band-pass filter for the human voice frequency range (85Hz - 3000Hz)
        bandPassFilterRef.current.type = 'bandpass';
        bandPassFilterRef.current.frequency.value = 1000; // Center frequency of bandpass filter
        bandPassFilterRef.current.Q.value = 1; // Quality factor (controls bandwidth)

        microphoneRef.current = audioContextRef.current.createMediaStreamSource(stream);
        microphoneRef.current.connect(bandPassFilterRef.current);
        bandPassFilterRef.current.connect(analyserNodeRef.current);

        analyserNodeRef.current.fftSize = 2048;
        const bufferLength = analyserNodeRef.current.frequencyBinCount;
        const dataArray = new Float32Array(bufferLength); // Use Float32Array for more precision

        const updateAudioData = () => {
          analyserNodeRef.current?.getFloatFrequencyData(dataArray); // Get more accurate frequency data

          // Calculate RMS for volume (sound intensity)
          const rms = calculateRMS(dataArray);
          console.log(rms)
          // Normalize RMS to a range of 0-1
          const volume = normalizeRMS(rms); 
  
          // Only dispatch if the volume is above a threshold
          if (volume > 0.05) {
            // Dispatch the normalized volume value
            dispatch(setCurrentAudioVolume(volume));

            // Calculate the frequency and determine the pitch
            const frequency = calculateFrequency(dataArray);
            const pitch = frequencyToPitch(frequency);

            // Dispatch the detected pitch
            dispatch(setCurrentAudioPitch(pitch));
          }

          requestAnimationFrame(updateAudioData);
        };

        updateAudioData();
      } catch (err) {
        console.error('Microphone access failed:', err);
      }
    };

    setupAudio();

    return () => {
      if (microphoneRef.current) microphoneRef.current.disconnect();
      if (audioContextRef.current) audioContextRef.current.close();
    };
  }, [dispatch]);

  return null; // No UI rendering needed
};

export default useAudioInput;