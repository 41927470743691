import React, { useEffect, useRef, useState } from 'react';
import { useThree } from '@react-three/fiber';
import * as THREE from 'three';
import { DeviceOrientationControls } from 'three-stdlib';

const GyroscopeControls: React.FC = () => {
  const { camera, gl } = useThree();
  const controlsRef = useRef<DeviceOrientationControls | null>(null);
  const [isReady, setIsReady] = useState(false);

  useEffect(() => {
    // DeviceOrientationControls 초기화
    const controls = new DeviceOrientationControls(camera);
    controlsRef.current = controls;


    const handleOrientation = (event: DeviceOrientationEvent) => {
      if (event.alpha !== null && event.beta !== null && event.gamma !== null) {
        controls.update();
      }
    };
 
    // deviceorientation 이벤트 리스너 추가
    window.addEventListener('deviceorientation', handleOrientation);

    // 장치가 자이로 정보를 제공하면 활성화
    setIsReady(true);

    // cleanup
    return () => {
      window.removeEventListener('deviceorientation', handleOrientation);
      controls.dispose();
    };
  }, [camera]);

  useEffect(() => {
    if (isReady && gl) {
      // Simply call gl.setAnimationLoop with the update function
      gl.setAnimationLoop((frame) => {  // Accept 'frame' parameter here
        if (controlsRef.current) {
          controlsRef.current.update();
        }
      });

      // No need to store 'animationId', so no return value
      return () => {
        // No need to stop animation loop here, as gl.setAnimationLoop handles it automatically
      };
    }
  }, [gl, isReady]);

  return null;
};

export default GyroscopeControls;
