import React, { useRef } from 'react';
import styled from 'styled-components';
import { Canvas, useFrame, useThree } from '@react-three/fiber';
import { CameraControls, Environment, Text3D, useGLTF } from '@react-three/drei';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../store';
import { setCameraDirection, setCameraPosition, setCameraUp } from '../store/concentricCirclesSlice';
import * as THREE from 'three';
import ImportedImage from 'components/ImportedImage';
import ControlPanel from 'components/ControlPanel';
import { useWebcam } from 'hooks/useWebcam';
import GyroscopeControls from './GyroscopeControls';
import VideoPlane from 'components/VideoPlane';
import useAudioInput from 'hooks/useAudioInput'; // Import the custom hook

const isMobile = () => /Android|iPhone|iPad|iPod|Opera Mini|IEMobile|WPDesktop/i.test(navigator.userAgent);

const SafeGLTF = (path: string) => {
  try {
    return useGLTF(path);
  } catch (error) {
    console.error(`Failed to load GLTF model at ${path}`, error);
    return { scene: new THREE.Scene() }; // Return an empty scene as fallback
  }
};

const Sign = () => {
  const { scene } = SafeGLTF('./models/sign1211.glb');
  return scene ? <primitive object={scene} position={[0, 0, -2]} scale={1} rotation={[0, 4.5, 0]} /> : null;
};

function ConcentricCirclesPage() {
  const mobile = isMobile();
  const videoRef = useWebcam();
  const dispatch = useDispatch();
  const { imageTextures = [], currentAudioVolum: audioVolum = 0, currentAudioPitch: audioPitch = 0, selectedHDRI, text3D, cameraPosition, cameraDirection, cameraUp } = useSelector(
    (state: RootState) => state.concentricCircles
  );
  const sphereScale = Math.max(1, (audioVolum || 0) / 20);

  const Mirrorball = () => {
    const { scene } = SafeGLTF('./models/Mirrorball.glb');
    const mirrorballRef = useRef<THREE.Object3D>(null);
    useFrame(() => {
      if (mirrorballRef.current) {
        mirrorballRef.current.rotation.y += 0.01;
      }
    });

    return scene ? <primitive ref={mirrorballRef} object={scene} position={[5, 3, 0]} scale={sphereScale} /> : null;
  };

  const CameraPositionUpdater = () => {
    const { camera } = useThree();
    const prevPositionRef = useRef<THREE.Vector3 | null>(null);
    const prevDirectionRef = useRef<THREE.Vector3 | null>(null);
    const prevUpRef = useRef<THREE.Vector3 | null>(null);

    useFrame(() => {
      if (camera) {
        const currentPosition = camera.position.clone();
        const currentDirection = camera.getWorldDirection(new THREE.Vector3());
        const currentUp = camera.up.clone();

        const hasPositionChanged =
          !prevPositionRef.current || currentPosition.distanceTo(prevPositionRef.current) > 0.001;
        const hasDirectionChanged =
          !prevDirectionRef.current || currentDirection.distanceTo(prevDirectionRef.current) > 0.001;
        const hasUpChanged =
          !prevUpRef.current || currentUp.distanceTo(prevUpRef.current) > 0.001;

        if (hasPositionChanged || hasDirectionChanged || hasUpChanged) {
          dispatch(setCameraPosition(currentPosition));
          dispatch(setCameraDirection(currentDirection));
          dispatch(setCameraUp(currentUp));
          prevPositionRef.current = currentPosition;
          prevDirectionRef.current = currentDirection;
          prevUpRef.current = currentUp;
        }
      }
    });

    return null;
  };

  // Call the custom hook to handle audio input
  useAudioInput(); // This will start the audio processing and dispatch actions

  return (
    <Wrapper>
      <Canvas camera={{ position: [0, 0, 0] }}>
        <CameraPositionUpdater />
        {mobile ? (
          <GyroscopeControls />
        ) : (
          <CameraControls minPolarAngle={Math.PI / 4} maxPolarAngle={Math.PI / 1.5} />
        )}
        <Environment files={selectedHDRI || ''} background />
        <VideoPlane position={[0, 0, -15]} selectedHDRI={selectedHDRI} />
        {imageTextures.map((image, index) => (
          <ImportedImage
            key={index}
            TextureImage={image}
            name={`image-${index}`}
            texture={image.url}
            position={image.position}
          />
        ))}
        {text3D && (
          <Text3D
            font="/fonts/Jalnan 2 TTF_Regular.typeface.json"
            size={1.0}
            height={0.5}
            bevelEnabled
            bevelThickness={0.03}
            bevelSize={0.02}
            position={[10, -4, -10]}
            rotation={[0, -1, 0]}
          >
            {text3D}
            <meshStandardMaterial metalness={1} />
          </Text3D>
        )}
        <Mirrorball />
      </Canvas>

      <video ref={videoRef} style={{ display: 'none' }} />
      <ControlPanel cameraPosition={cameraPosition} cameraDirection={cameraDirection} cameraUp={cameraUp} />
      {audioPitch} {/* Display the detected pitch */}
    </Wrapper>
  );
}

export default ConcentricCirclesPage;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  position: relative;
`;