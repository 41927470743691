// import * as react from 'react';
import * as ReactDOM from 'react-dom/client';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import './typography.css'; // 이미 있는 임포트
import { Provider } from 'react-redux';
import { HelmetProvider } from 'react-helmet-async';
import { loadableReady } from '@loadable/component';

import reportWebVitals from './reportWebVitals';

import store from './store';

const contains = document.getElementById('root')!;
const root = ReactDOM.createRoot(contains);

if (process.env.NODE_ENV === 'production') {
  loadableReady(() => {
    ReactDOM.hydrateRoot(
      contains,
      <HelmetProvider>
        <Provider store={store}>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </Provider>
      </HelmetProvider>,
    );
  });
} else {
  root.render(
    <HelmetProvider>
      <Provider store={store}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </Provider>
    </HelmetProvider>,
  );
}

reportWebVitals();
