// concentricCirclesSlice.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ImageItem } from '../Type/Types';
import * as THREE from 'three';

interface ConcentricCirclesState {
  selectedHDRI: string;
  currentAudioVolum: number;
  currentAudioPitch: string;
  selectedImage: ImageItem | null;
  imageTextures: ImageItem[];
  text3D: string;
  cameraPosition: THREE.Vector3;
  cameraDirection: THREE.Vector3;
  cameraUp: THREE.Vector3;
}

const initialState: ConcentricCirclesState = {
  selectedHDRI: './Hdr/red.exr',
  currentAudioVolum: 0,
  currentAudioPitch: 'do',
  selectedImage: null,
  imageTextures: [],
  text3D: '',
  cameraPosition: new THREE.Vector3(0, 0, 0),
  cameraDirection: new THREE.Vector3(0, 0, 0),
  cameraUp: new THREE.Vector3(0, 0, 0),
};

const concentricCirclesSlice = createSlice({
  name: 'concentricCircles',
  initialState,
  reducers: {
    setSelectedHDRI(state, action: PayloadAction<string>) {
      state.selectedHDRI = action.payload;
    },
    setSelectedImage(state, action: PayloadAction<ImageItem | null>) {
      state.selectedImage = action.payload;
    },
    setImageTextures(state, action: PayloadAction<ImageItem[]>) {
      state.imageTextures = action.payload;
    },
    setCurrentAudioVolume(state, action: PayloadAction<number>) {
      state.currentAudioVolum = action.payload;
    },
    setCurrentAudioPitch(state, action: PayloadAction<string>) {
      state.currentAudioPitch = action.payload;
    },
    set3DText(state, action: PayloadAction<string>) {
      state.text3D = action.payload;
    },
    removeImage(state, action: PayloadAction<ImageItem>) {
      const imageToRemove = action.payload;
      // 이미 선택된 이미지가 삭제된 이미지와 같으면 초기화
      if (state.selectedImage?.url === imageToRemove.url) {
        state.selectedImage = null;
      }
      state.imageTextures = state.imageTextures.filter(
        (image) => image.url !== imageToRemove.url,
      );
    },
    setCameraPosition(state, action: PayloadAction<THREE.Vector3>) {
      state.cameraPosition.copy(action.payload); // 객체 복사로 성능 최적화
    },
    setCameraDirection(state, action: PayloadAction<THREE.Vector3>) {
      state.cameraDirection.copy(action.payload); // 객체 복사로 성능 최적화
    },
    setCameraUp(state, action: PayloadAction<THREE.Vector3>) {
      state.cameraUp.copy(action.payload); // 객체 복사로 성능 최적화
    },
    setImageScale(
      state,
      action: PayloadAction<{ image: ImageItem; scale: number }>,
    ) {
      const { image, scale } = action.payload;
      const index = state.imageTextures.findIndex(
        (img) => img.url === image.url,
      );
      if (index !== -1) {
        state.imageTextures[index] = {
          ...state.imageTextures[index],
          Scale: scale,
        };
      }
    },
  },
});

export const {
  setSelectedHDRI,
  setCurrentAudioVolume,
  setCurrentAudioPitch,
  setSelectedImage,
  setImageTextures,
  set3DText,
  removeImage,
  setCameraPosition,
  setCameraDirection,
  setCameraUp,
  setImageScale,
} = concentricCirclesSlice.actions;

export default concentricCirclesSlice.reducer;
